import React from "react";
import "./inputField.css";

const InputField = ({ label, type, onChange, placeholder, error, onBlur }) => {
  return (
    <>
      <div className="input_container">
        <div className="input_label_container">
          <label className="lms_label">{label}</label>

          <input
            className="login_input"
            type={type}
            onBlur={onBlur}
            placeholder={placeholder}
            onChange={onChange}
          />
        </div>
        {error && <span className="lms_input_error">{error}</span>}
      </div>
    </>
  );
};

export default InputField;
