import React from "react";
import "./StyleUsButton.css";
import { Link } from "react-router-dom";

const StyleUsButton = ({ label, style, onMouseEnter, onMouseLeave }) => {
  return (
    <>
      <Link
        to="/armoire/products"
        style={{ color: "inherit", textDecoration: "inherit" }}
      >
        <div
          className="styleus_button"
          style={style}
          onMouseEnter={() => onMouseEnter()}
          onMouseLeave={() => onMouseLeave()}
        >
          {label}
        </div>
      </Link>
    </>
  );
};

export default StyleUsButton;
