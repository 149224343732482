import React, { useState, useEffect } from "react";
import "./ProductPage.css";
import { useParams } from "react-router";
import products from "../../productData";
import { Link } from "react-router-dom";
import closeIcon from "../../../../assets/x.png";
import BlackHanger from "../../../../assets/clothing_hanger_black.png";
import axios from "axios";
import WhiteHanger from "../../../../assets/clothing_hanger_white.png";
import tryThreedD from "../../../../assets/Artboard 1 3.png";

const ArmoireProductPage = () => {
  const [openIframe, setOpenIframe] = useState(false);
  const [armoireProducts, setArmoireProducts] = useState();

  const [loading, setLoading] = useState(false);
  const params = useParams();
  const { id } = params;
  const productId = parseInt(id);

  useEffect(() => {
    try {
      setLoading(true);
      axios({
        method: "get",
        url: "https://zeblobstorage.blob.core.windows.net/zeblob/ProjectDb/ZDViewer/ZDViewer_Extension/StyleMe/StyleMeDashboard.json",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        if (res.status === 200) {
          const filteredArmoire = res?.data.dashboard.filter(
            (product) => product.id === productId
          );
          setArmoireProducts(filteredArmoire[0]);
          setLoading(false);
        }
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <>
      {openIframe && (
        <div className="styleus_iframe">
          <iframe
            src="https://staging.view.zdviewer.com/products/BOUTIQUE"
            title="title"
            scrolling="no"
          ></iframe>
          <div
            className="close_icon_iframe"
            onClick={() => setOpenIframe(false)}
          >
            <img src={closeIcon} alt="close-icon" />
          </div>
        </div>
      )}

      {loading ? (
        <div className="lms_loader_screen" style={{ marginTop: "5rem" }}>
          <div className="lms_loader"></div>
        </div>
      ) : (
        <>
          <div className="armoire_product_page_container">
            <Link
              to="/armoire/products"
              style={{
                color: "inherit",
                fontSize: "1.5rem",
                textDecoration: "inherit",
              }}
            >
              &larr;&nbsp;Back
            </Link>
            <div className="armoire_product_page_content">
              {armoireProducts && (
                <>
                  <div className="armoire_image_container">
                    <img src={armoireProducts?.image} alt="armoire_image" />
                    <div
                      className="trythreed_button"
                      style={{
                        backgroundColor: openIframe ? "black" : "white",
                        boxShadow:
                          "   0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                      }}
                      onClick={() => {
                        setOpenIframe(!openIframe);
                      }}
                    >
                      <img
                        src={openIframe ? WhiteHanger : BlackHanger}
                        alt="hanger"
                      />
                      <div
                        className="armoire_threed_text"
                        style={{ color: openIframe ? "white" : "" }}
                      >
                        Try 3D
                      </div>
                    </div>
                  </div>

                  <div className="armoire_description_group">
                    <div className="armoire_main_heading">
                      {armoireProducts?.name}
                    </div>
                    <div className="style_us_colorName">
                      COLOR :<span> {armoireProducts?.color}</span>
                    </div>
                    <div className="style_us_colorName">
                      DESCRIPTION : <span> {armoireProducts?.description}</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ArmoireProductPage;
