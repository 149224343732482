import React, { useState } from "react";
import "./login.css";
import laptopIcon from "../../../../assets/laptop_lms.png";
import InputField from "../../components/inputField";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");

  let navigate = useNavigate();
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Validation
    let valid = true;
    if (!name.trim()) {
      setNameError("Name is required");
      valid = false;
    } else {
      setNameError("");
    }

    if (!email.trim()) {
      setEmailError("Email is required");
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email format");
      valid = false;
    } else {
      setEmailError("");
    }

    if (valid) {
      try {
        await axios({
          method: "post",
          url: "https://zdportalservice.azurewebsites.net/lms/login",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            username: name,
            email: email,
          },
        }).then((res) => {
          if (res?.status === 201 || res?.status === 200) {
            navigate(
              `/lms/lesson?userid=${encodeURIComponent(res?.data?.userid)}`
            );

            setName("");
            setEmail("");
          } else {
            alert("Oops! Something went wrong. Please try again.");
          }
        });
      } catch (error) {
        console.error("Error occurred during login:", error);
      }
    }
  };

  return (
    <div className="login_screen">
      <div className="lms_heading_container">
        <div className="lms_title_container">
          <div className="lms_main_heading">Welcome to Learning Platform</div>
          <div className="lms_sub_heading">
            A platform to learn and enhance your skills.
          </div>
          <div className="lms_unordered_group">
            <div className="list_group">
              <div className="lms_list_circle"></div>
              <div className="lms_list_text">3D learning</div>
            </div>
            <div className="list_group">
              <div className="lms_list_circle"></div>
              <div className="lms_list_text">
                Interactive Quizzes and assignments
              </div>
            </div>
          </div>
        </div>
        <div className="lms_image_container">
          <img src={laptopIcon} alt="laptop" className="lms_image" />
        </div>
      </div>
      <div className="input_half">
        <div className="lms_input_container">
          <div className="lms_input_heading">Learning Management System</div>
          <form onSubmit={handleFormSubmit}>
            <InputField
              label={"Name"}
              type={"text"}
              value={name}
              onChange={(event) => {
                setNameError("");
                setName(event.target.value);
              }}
              placeholder={"Enter your name"}
              error={nameError}
            />
            <InputField
              label={"Email ID"}
              type={"email"}
              value={email}
              onChange={(event) => {
                setEmailError("");
                setEmail(event.target.value);
              }}
              placeholder={"Enter your email id"}
              error={emailError}
            />
            <div className="lms_input_button_group">
              <button type="submit" className="lms_input_submit">
                SUBMIT
              </button>
              <div
                className="lms_admin_heading"
                onClick={() => navigate("/lms/admin?userid=admin&user=admin")}
              >
                I am admin
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
