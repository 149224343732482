import React, { useState, useEffect } from "react";
import "./userdashboard.css";
import axios from "axios";
import { useLocation } from "react-router";
import arrowUp from "../../../../assets/arrow.png";
import ItemCard from "../../components/itemCard";
import { useNavigate } from "react-router";
import Sidebar from "../../components/sidebar";
import acceptIcon from "../../../../assets/correct.png";
import alertIcon from "../../../../assets/warning.png";
import checkBoxIcon from "../../../../assets/checkbox.png";
import unCheckedIcon from "../../../../assets/radio-button-unchecked.png";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material";

const UserDashboard = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userid = searchParams.get("userid");
  const user = searchParams.get("user");

  const [username, setUsername] = useState("");
  const [selectedItem, setSelectedItem] = useState();
  const [userAssessmentList, setUserAssessmentList] = useState([]);

  const [userUserData, setUserUserData] = useState([]);
  const [userScore, setUserScore] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [menuLoading, setMenuLoading] = useState(true);

  console.log("userid>>>>>>####", userid);

  const navigate = useNavigate();

  useEffect(() => {
    try {
      axios({
        method: "get",
        url: `https://zdportalservice.azurewebsites.net/lms/getUserProgress?userid=${userid}`,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        console.log("userid RESPONSE>>>", res);
        setUserAssessmentList(res?.data);
        setIsLoading(false);
        if (res?.status === 200) {
        } else {
          alert("Oops! Something went wrong. Please try again.");
        }
      });
    } catch (error) {
      console.error("Error occurred during login:", error);
    }
  }, []);

  console.log("userAssessmentList", userAssessmentList);

  useEffect(() => {
    console.log("userid selectedItem########>>>", selectedItem);

    if (selectedItem) {
      try {
        setMenuLoading(true);
        axios({
          method: "get",
          url: `https://zdportalservice.azurewebsites.net/lms/getUserResponses?userid=${userid}&setid=${selectedItem}`,
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => {
          if (res?.status === 200) {
            console.log("userid selectedItem########>>>", res?.data);

            setUserUserData(res?.data);
            console.log("setUserUserData", userUserData);
            setMenuLoading(false);
          } else {
            alert("Oops! Something went wrong. Please try again.");
          }
        });
      } catch (error) {
        console.error("Error occurred during login:", error);
      }
    }
  }, [selectedItem]);
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} placement="top" arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: "1rem",
      padding: "0.3rem 1rem",
    },
  }));

  return (
    <>
      <Sidebar query={userid} user={user} />
      <div className="dashboard_container">
        <div className="dashboard_heading">Welcome,</div>
        <div className="userDashboard_heading_group">
          <div className="userdashboard_heading">Assessment taken</div>
          {userAssessmentList.length != 0 && (
            <div
              className="performance_button"
              onClick={() => {
                navigate(
                  `/lms/results/performance?userid=${encodeURIComponent(
                    userid
                  )}`
                );
              }}
            >
              {user === "admin" ? "Performance" : "My Performance"}
            </div>
          )}
        </div>

        <div className="dashboard_item_container">
          {/* <ItemCard score={userScore} name={"Prerequisites"} /> */}

          {isLoading ? (
            <>
              <div className="lms_loader"></div>
            </>
          ) : (
            <>
              {userAssessmentList.length === 0 ? (
                <div className="userDashboard_no_result">
                  No User Details found
                </div>
              ) : (
                <>
                  {userAssessmentList?.map((item, index) => (
                    <div
                      className="userDashboard_item"
                      style={{
                        border: item?.assessmentCompleted
                          ? "1px solid #00ba00"
                          : item?.lessonCompleted
                          ? "1px solid #ff9d00"
                          : "1px solid #1a1b37",
                      }}
                      key={index}
                    >
                      <div className="userDashboard_title_group">
                        <div className="userDashboard_title">
                          {item?.casename}
                        </div>
                        <div className="userDashboard_score_group">
                          <img
                            style={{
                              transform:
                                menuOpen && item?.assessmentid === selectedItem
                                  ? "rotate(0deg)"
                                  : "rotate(180deg)",
                            }}
                            src={arrowUp}
                            alt="up-arrow"
                            onClick={() => {
                              setMenuOpen(
                                selectedItem === item?.assessmentid && menuOpen
                                  ? false
                                  : true
                              );
                              setSelectedItem(item?.assessmentid);
                            }}
                          />

                          {item === null ? (
                            <BootstrapTooltip title="no progress">
                              <img src={checkBoxIcon} />
                            </BootstrapTooltip>
                          ) : !item?.lessonCompleted &&
                            !item?.assessmentCompleted ? (
                            <BootstrapTooltip title="Both Lesson and Test not completed">
                              <img src={unCheckedIcon} alt="unchecked ICon" />
                            </BootstrapTooltip>
                          ) : item?.lessonCompleted &&
                            !item?.assessmentCompleted ? (
                            <BootstrapTooltip title="Lesson Completed">
                              <img src={alertIcon} />
                            </BootstrapTooltip>
                          ) : (
                            <BootstrapTooltip title="Test Completed">
                              <img src={acceptIcon} />
                            </BootstrapTooltip>
                          )}
                        </div>
                      </div>
                      {menuOpen && item?.assessmentid === selectedItem && (
                        <div className="userDashboard_info_container">
                          {menuLoading ? (
                            <div className="lms_loader"></div>
                          ) : (
                            <>
                              {userUserData.length === 0 ? (
                                <>
                                  <div className="userDashboard_no_result">
                                    No User Details found
                                  </div>
                                </>
                              ) : (
                                <>
                                  {userUserData.map((userUserdata, index) => {
                                    const date = userUserdata?.updatedAt
                                      ? new Date(
                                          userUserdata.updatedAt
                                        ).toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "numeric",
                                          day: "numeric",
                                        })
                                      : "Unknown";
                                    const time = userUserdata?.updatedAt
                                      ? new Date(
                                          userUserdata.updatedAt
                                        ).toLocaleTimeString("en-US", {
                                          hour: "numeric",
                                          minute: "numeric",
                                          second: "numeric",
                                          hour12: true,
                                        })
                                      : "Unknown";
                                    return (
                                      <div className="userDashboard_info_item">
                                        <div className="userDashboard_flex_group">
                                          <div className="userDashboard_title">
                                            Date: {date}
                                          </div>
                                          <div className="userDashboard_title">
                                            Time: {time}
                                          </div>
                                        </div>
                                        <div className="userDashboard_flex_group">
                                          <div className="userDashboard_title">
                                            Score: {userUserdata?.score}
                                          </div>
                                          {user !== "admin" &&
                                          !userUserdata?.evaluationCompleted ? (
                                            <div>
                                              Evaluation yet to be completed
                                            </div>
                                          ) : (
                                            <div
                                              className="userDashboard_hyperlink"
                                              onClick={() => {
                                                {
                                                  user === "admin" &&
                                                  !userUserdata?.evaluationCompleted
                                                    ? navigate(
                                                        `/lms/evaluation?userid=${encodeURIComponent(
                                                          userid
                                                        )}&setid=${encodeURIComponent(
                                                          item?.assessmentid
                                                        )}&aId=${
                                                          userUserdata?.id
                                                        }`
                                                      )
                                                    : navigate(
                                                        `/lms/assessment?userid=${encodeURIComponent(
                                                          userUserdata.userid
                                                        )}&setid=${encodeURIComponent(
                                                          userUserdata.setid
                                                        )}&mode=answers&aId=${
                                                          userUserdata?.id
                                                        }&user=${encodeURIComponent(
                                                          user
                                                        )}
                                              `
                                                      );
                                                }
                                              }}
                                            >
                                              {user === "admin" &&
                                              !userUserdata?.evaluationCompleted
                                                ? "Evaluate"
                                                : "View Results"}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default UserDashboard;
