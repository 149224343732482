import React, { useState, useEffect } from "react";
import "./performance.css";
import downArrowIcon from "../../../../assets/down.png";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import axios from "axios";
import { useLocation } from "react-router";

const Performance = () => {
  const [menuOpen, setMenuOption] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userid = searchParams.get("userid");

  const [username, setUsername] = useState("");
  const [selectedItem, setSelectedItem] = useState();
  const [userAssessmentList, setUserAssessmentList] = useState([]);

  const [userUserData, setUserUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [menuLoading, setMenuLoading] = useState(true);

  const toggleMenu = () => {
    setMenuOption(!menuOpen);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setMenuOption(false); // Close the dropdown after selecting an option
    setSelectedItem(option?.assessmentid);
  };

  useEffect(() => {
    try {
      axios({
        method: "get",
        url: `https://zdportalservice.azurewebsites.net/lms/getUserProgress?userid=${userid}`,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        setSelectedItem(res.data[0].assessmentid);
        setUserAssessmentList(res?.data);
        setIsLoading(false);
      });
    } catch (error) {
      console.error("Error occurred during login:", error);
    }
  }, []);

  useEffect(() => {
    if (selectedItem) {
      try {
        setMenuLoading(true);
        axios({
          method: "get",
          url: `https://zdportalservice.azurewebsites.net/lms/getUserResponses?userid=${userid}&setid=${selectedItem}`,
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => {
          if (res?.status === 200) {
            // Modify the data to include attempt numbers
            const modifiedData = res.data.map((item, index) => ({
              ...item,
              attempt: `Attempt ${index + 1}`,
            }));
            setUserUserData(modifiedData);
            setMenuLoading(false);
          } else {
            alert("Oops! Something went wrong. Please try again.");
          }
        });
      } catch (error) {
        console.error("Error occurred during login:", error);
      }
    }
  }, [selectedItem]);

  return (
    <div className="performance_container">
      <div className="dashboard_heading">Welcome,</div>
      <div className="performance_subheading">Assessment taken</div>
      <div className="performance_body_container">
        <div className="performance_item">
          <div className="performance_dropdown" onClick={toggleMenu}>
            <div className="performance_item_title">
              {selectedOption
                ? selectedOption.casename
                : userAssessmentList[0]?.casename}
            </div>
            <img src={downArrowIcon} alt="down-arrow" />
            {menuOpen && (
              <div className="performance_dropdown_menu">
                {userAssessmentList.map((option) => (
                  <div
                    key={option.value}
                    className="performance_dropdown_menu_item"
                    onClick={() => selectOption(option)}
                  >
                    {option.casename}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="perfomance_graph_container">
            {userUserData.length === 0 ? (
              <div className="no_data">No data available</div>
            ) : (
              <div className="graph_box">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={userUserData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                    barSize={20}
                  >
                    <XAxis dataKey="attempt">
                      <Label
                        value="Attempt"
                        offset={0}
                        position="insideBottom"
                      />
                    </XAxis>

                    <YAxis
                      domain={[0, 5]}
                      label={{ value: "Score", angle: -90 }}
                    />
                    <Tooltip />

                    <CartesianGrid strokeDasharray="3 3" />
                    <Bar
                      dataKey="score"
                      fill="#8884d8"
                      background={{ fill: "#eee" }}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Performance;
