import React, { useEffect, useState } from "react";
import downIcon from "../../../../assets/down.png";
import "./lessons.css";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import PlayIcon from "../../../../assets/play-button.png";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import acceptIcon from "../../../../assets/correct.png";
import alertIcon from "../../../../assets/warning.png";
import checkBoxIcon from "../../../../assets/checkbox.png";
import unCheckedIcon from "../../../../assets/radio-button-unchecked.png";
import Sidebar from "../../components/sidebar";
import LmsPopup from "../../components/lmsPopup";

const Lessons = () => {
  const [isChecked, setIsChecked] = useState(false);

  const [assesmentList, setAssesmentList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);
  const [popupDesc, setPopupDesc] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [cohortName, setCohortName] = useState();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const userid = searchParams.get("userid");

  const navigate = useNavigate();

  useEffect(() => {
    try {
      axios({
        method: "get",
        url: `https://zdportalservice.azurewebsites.net/lms/getCohert?userid=${encodeURIComponent(
          userid
        )}`,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        const defaultCohort = res.data.coherts.find(
          (item) => item.cohertid === res.data.user.cohertid
        );

        setCohortName(defaultCohort.name);
      });
    } catch (err) {
      console.log("Error in getting progress details", err);
    }
  }, []);

  useEffect(() => {
    try {
      axios({
        method: "get",
        url: `https://zdportalservice.azurewebsites.net/lms/getAllCases?userid=${encodeURIComponent(
          userid
        )}`,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        if (res?.status === 200) {
          setAssesmentList(res.data);
          setIsLoading(false);
        } else {
          alert("Oops! Something went wrong. Please try again.");
        }
      });
    } catch (error) {
      console.error("Error occurred during login:", error);
    }
  }, []);

  const handleNewtab = (sid, itemurl, caseid) => {
    const url = new URL(itemurl);
    url.searchParams.append("userid", userid);
    url.searchParams.append("setid", sid);
    url.searchParams.append("caseid", caseid);

    url.searchParams.append("mode", "questions");

    window.open(url.toString(), "_blank");
  };

  const handleViewResult = () => {
    navigate(`/lms/results?userid=${encodeURIComponent(userid)}`);
  };

  const handleKnowMore = async (caseid, casename, progress, setid) => {
    if (progress === null) {
      try {
        setIsDisable(true);
        await axios({
          method: "post",
          url: `https://zdportalservice.azurewebsites.net/lms/postUserProgress`,
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            userid: userid,
            caseid: caseid,

            lessonCompleted: false,
            assessmentCompleted: false,
            meta: {},
          },
        }).then((res) => {
          if (res?.status === 200) {
            setIsDisable(false);
            navigate(
              `/lms/lesson/1?setid=${encodeURIComponent(
                setid
              )}&id=iframe&userid=${encodeURIComponent(
                userid
              )}&caseid=${encodeURIComponent(
                caseid
              )}&casename=${encodeURIComponent(casename)}`
            );
          } else {
            alert("Oops! Something went wrong. Please try again.");
          }
        });
      } catch (err) {
        console.log("Error in getting progress details", err);
      } finally {
      }
    } else {
      navigate(
        `/lms/lesson/1?setid=${encodeURIComponent(
          setid
        )}&id=iframe&userid=${encodeURIComponent(
          userid
        )}&caseid=${encodeURIComponent(caseid)}&casename=${encodeURIComponent(
          casename
        )}`
      );
    }
  };

  const handlePopup = () => {
    setOpenPopup(!openPopup);
    setPopupDesc("Please complete the lesson to access the assessment");
  };

  return (
    <>
      {openPopup && (
        <div
          className=""
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100dvh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "20",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <LmsPopup
            desc={popupDesc}
            OnClose={() => {
              setPopupDesc("");
              setOpenPopup(!openPopup);
            }}
          />
        </div>
      )}
      <Sidebar query={userid} />
      <div className="lesson_screen">
        <div className="dropdown_container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <div className="dropdown_title">Car Care and Maintenance</div>
            <div className="cohort_box">Cohort: {cohortName || "-"}</div>
          </div>
          <div className="dropdown_box">
            <div className="view_results_button" onClick={handleViewResult}>
              View Results
            </div>
            {/* <div
              className="dropdown_box_container"
              onClick={() => setOpenDropdown(!openDropdown)}
            >
              {openDropdown && (
                <div className="dropdown_menu_container">
                  {options.map((option) => {
                    return (
                      <div
                        className="dropdown_option_text"
                        key={option.value}
                        onClick={() => handleOptionSelect(option)}
                      >
                        {option.label}
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="dropdown_box_title">{selectedLesson}</div>
              <img src={downIcon} alt="down-icon" />
            </div> */}
          </div>
        </div>

        {isLoading ? (
          <>
            <div className="lms_loader_screen">
              <div className="lms_loader"></div>
            </div>
          </>
        ) : (
          <>
            {assesmentList.length === 0 ? (
              <div className="lms_loader_screen">
                <div className="userDashboard_no_result">No Lessons found</div>
              </div>
            ) : (
              <>
                {assesmentList?.map((item, index) => (
                  <div className="lessons_container">
                    <div
                      className="lessons_card"
                      style={{
                        border: item?.progress?.assessmentCompleted
                          ? "1px solid #00ba00"
                          : item?.progress?.lessonCompleted
                          ? "1px solid #ff9d00"
                          : "1px solid #1a1b37",
                      }}
                    >
                      <div className="card_divider">
                        <div className="text_container">
                          <div className="text_title">{item?.case.name}</div>
                          <div className="option_subGroup">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "center",
                                gap: "0.5rem",
                                fontSize: "1.3rem",
                              }}
                            >
                              <div style={{ width: "8rem" }}>Know more</div>

                              <img
                                style={{
                                  width: "1.5rem",
                                  cursor: "pointer",
                                  pointerEvents: isDisable ? "none" : "",
                                }}
                                src={PlayIcon}
                                alt="play-icon"
                                onClick={() => {
                                  console.log("item before call", item) ||
                                    handleKnowMore(
                                      item?.case?.id,
                                      item?.case.name,
                                      item?.progress,
                                      item?.case?.assessmentId
                                    );
                                }}
                              />
                            </div>
                            {item?.case?.type === "website" && (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    justifyContent: "center",
                                    gap: "0.5rem",
                                    fontSize: "1.3rem",
                                  }}
                                >
                                  <div style={{ width: "8rem" }}>
                                    Assessment
                                  </div>

                                  <img
                                    style={{
                                      width: "1.5rem",
                                      cursor: "pointer",
                                    }}
                                    src={PlayIcon}
                                    alt="play-icon"
                                    onClick={() => {
                                      item?.progress === null ||
                                      !item?.progress.lessonCompleted
                                        ? handlePopup()
                                        : handleNewtab(
                                            item?.case.assessmentId,
                                            item.case.url,
                                            item?.case.id
                                          );
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="checkbox_img">
                          {item?.progress === null ? (
                            <img src={checkBoxIcon} />
                          ) : !item?.progress.lessonCompleted &&
                            !item?.progress.assessmentCompleted ? (
                            <img src={unCheckedIcon} alt="unchecked ICon" />
                          ) : item?.progress.lessonCompleted &&
                            !item?.progress.assessmentCompleted ? (
                            <img src={alertIcon} />
                          ) : (
                            <img src={acceptIcon} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Lessons;
