import React from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import ZdWhiteLogo from "../../../../assets/zd viewer beta logo whiite.png";
import ZdBlackLogo from "../../../../assets/zd viewer beta logo two 1.png";
import { useLocation } from "react-router-dom";

const ArmoireNavbar = () => {
  const location = useLocation();
  console.log(location.pathname);
  return (
    <>
      <div className="armoire_navbar_container">
        <nav>
          <Link to="/armoire">
            <img
              src={location.pathname === "/armoire" ? ZdWhiteLogo : ZdBlackLogo}
              alt="logo"
            />
          </Link>
        </nav>
      </div>
      {location.pathname === "/armoire" ? null : (
        <div
          style={{
            backgroundColor: "black",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.1rem",
            padding: "0.1rem",
          }}
        >
          Summer Sale, get upto 40% off
        </div>
      )}
    </>
  );
};

export default ArmoireNavbar;
