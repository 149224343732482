import React, { useState, useEffect } from "react";
import axios from "axios";
import "./admin.css";

import ItemCard from "../../components/itemCard";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import CohortMenu from "../../components/cohortMenu";
import LmsPopup from "../../components/lmsPopup";
import { toast } from "react-toastify";

const AdminDashboard = () => {
  const [adminData, setAdminData] = useState([]);
  const [highestScorer, setHighestScorer] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [cohortMenu, setCohortMenu] = useState(false);
  const [cohortData, setCohortData] = useState();
  const [cohertLoading, setCohertLoading] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);
  const [popupDesc, setPopupDesc] = useState("");
  const [deleteUserid, setDeleteUserid] = useState();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const user = searchParams.get("user");

  useEffect(() => {
    try {
      axios({
        method: "get",
        url: "https://zdportalservice.azurewebsites.net/lms/getAllUsers?userid=admin",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          setAdminData(response.data);
          setIsLoading(false);
          findHighestScorer(response.data);
        })
        .catch((error) => {
          console.error("Error occurred during fetch:", error);
        });
    } catch (error) {
      console.error("Error occurred during fetch:", error);
    }
  }, []);

  const navigate = useNavigate();

  const handleItemClick = (userid) => {
    navigate(`/lms/results?userid=${encodeURIComponent(userid)}&user=${user}`);
  };

  const findHighestScorer = (data) => {
    if (data.length > 0) {
      let highestScoreUser = data.reduce((prev, current) =>
        prev.averagescore > current.averagescore ? prev : current
      );
      setHighestScorer(highestScoreUser);
    }
  };

  const handleDeleteItem = async (userid) => {
    try {
      await axios({
        method: "get",
        url: `https://zdportalservice.azurewebsites.net/lms/deleteUser?userid=${encodeURIComponent(
          userid
        )}`,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        if (res?.status === 200) {
          console.log("RESPONSE  DELETE >>>", res?.data?.message);
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      });
      const updatedData = adminData.filter((data) => data.userid !== userid);
      setAdminData(updatedData);
      findHighestScorer(updatedData);
    } catch (error) {
      console.error("Error occurred during delete:", error);
    }
  };

  const handleCohortOpen = async (username, userid) => {
    setCohortMenu(!cohortMenu);

    try {
      await axios({
        method: "get",
        url: `https://zdportalservice.azurewebsites.net/lms/getCohert?userid=${encodeURIComponent(
          userid
        )}`,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        setCohortData(res.data);
        setCohertLoading(false);
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {openPopup && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100dvh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "20",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <LmsPopup
            desc={popupDesc}
            OnClose={() => {
              setOpenPopup(false);
              setPopupDesc("");
              setDeleteUserid("");
            }}
            name="Delete"
            onClick={() => {
              handleDeleteItem(deleteUserid);
              setPopupDesc("");
              setDeleteUserid("");
              setOpenPopup(false);
            }}
          />
        </div>
      )}
      {cohortMenu && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100dvh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "20",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          {cohertLoading ? (
            <div className="lms_loader"></div>
          ) : (
            <>
              <CohortMenu
                cohortData={cohortData}
                onclose={() => setCohortMenu(!cohortMenu)}
              />
            </>
          )}
        </div>
      )}
      <div className="dashboard_container">
        <div className="dashboard_heading">Welcome Admin</div>
        {/* {highestScorer && (
          <div className="dashboard_highest_score">
            Highest Scorer - {highestScorer.username}
          </div>
        )} */}
        <div className="dashboard_item_container">
          {isLoading ? (
            <>
              <div className="lms_loader"></div>
            </>
          ) : (
            <>
              {adminData.length === 0 ? (
                <div className="userDashboard_no_result">No Users found</div>
              ) : (
                <>
                  {adminData.map((data) => {
                    return (
                      <ItemCard
                        key={data.email} // Ensure each child element has a unique "key" prop
                        score={data.userid}
                        name={data.username}
                        onEditClick={() => {
                          handleCohortOpen(data.username, data.userid);
                        }}
                        onClick={() => handleItemClick(data.userid)}
                        onDelete={() => {
                          setOpenPopup(true);
                          setPopupDesc(
                            "Are you sure you want to delete this user?"
                          );
                          // handleDeleteItem(data.userid);
                          setDeleteUserid(data.userid);
                        }}
                      />
                    );
                  })}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
