import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import "./manualEvaluation.css";
import axios from "axios";
import { toast } from "react-toastify";

const ManualEvaluation = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userid = searchParams.get("userid");
  const setid = searchParams.get("setid");
  const aId = searchParams.get("aId");
  const navigate = useNavigate();

  const [questionsData, setQuestionsData] = useState([]);
  const [descQues, setDescQues] = useState([]);
  const [descScore, setDescScore] = useState();
  const [loading, setLoading] = useState(true);
  const [resAns, setResAns] = useState([]);
  const [feedbackData, setFeedbackData] = useState([]);

  useEffect(() => {
    try {
      axios({
        method: "get",
        url: `https://zdportalservice.azurewebsites.net/lms/getAssesment?setid=${encodeURIComponent(
          setid
        )}`,
      }).then((res) => {
        if (res.status === 200) {
          setQuestionsData(res.data);
        }
      });
    } catch (err) {
      console.log("error", err);
    }
  }, []);

  useEffect(() => {
    if (questionsData?.length)
      try {
        axios({
          method: "get",
          url: `https://zdportalservice.azurewebsites.net/lms/getUserResponses?userid=${userid}&setid=${setid}`,
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => {
          console.log("res", res.data[0].answerkey);
          const aidData = res?.data?.find((item) => item.id === parseInt(aId));
          setDescScore(parseInt(aidData.score));

          setResAns(aidData.answerkey);
          const renameAnswerKey = (data) =>
            data.map(({ question, ...rest }) => ({
              ...rest,
              wrongAnswer: rest.answer,
            }));
          const modifiedData = renameAnswerKey(aidData.answerkey);

          let modifiedQuestions = modifiedData.map((question) => {
            delete question.answer;
            return question;
          });
          let mergedArray = [];

          for (let i = 0; i < questionsData.length; i++) {
            let obj = questionsData[i];
            if (i < modifiedQuestions.length) {
              Object.assign(obj, modifiedQuestions[i]);
            }
            mergedArray.push(obj);
          }
          const descriptiveQues = mergedArray.filter(
            (item) => item.answerType === "descriptive"
          );

          setLoading(false);
          console.log("descriptiveQues", descriptiveQues);
          setDescQues(descriptiveQues);
          const feedbackData = descriptiveQues.map(() => ({
            score: "",
            feedback: "",
          }));
          setFeedbackData(feedbackData);
        });
      } catch (err) {
        console.log("error", err);
      }
  }, [questionsData]);

  const handleScoreChange = (index, score) => {
    const updatedFeedbackData = [...feedbackData];
    updatedFeedbackData[index].score = score;
    setFeedbackData(updatedFeedbackData);
  };

  const handleFeedbackChange = (index, feedback) => {
    const updatedFeedbackData = [...feedbackData];
    updatedFeedbackData[index].feedback = feedback;
    setFeedbackData(updatedFeedbackData);
  };

  const handlePublish = async () => {
    // Check if any field is empty
    const hasEmptyField = feedbackData.some(
      (item) => item.score === "" || item.feedback === ""
    );
    if (hasEmptyField) {
      toast.error("Please fill in all fields before publishing.");
      return;
    }

    let curScore = descScore;
    try {
      const answerkeyset = [];

      feedbackData.forEach((item, index) => {
        const score = parseInt(item.score);
        curScore = curScore + score;
        const feedback = item.feedback;
        answerkeyset.push({
          question: index + 1,
          answer: descQues[index]?.wrongAnswer,
          feedback: [{ feedback: feedback }],
        });
      });

      const feedbackbody = {
        id: aId,
        userid: userid,
        setid: setid,
        score: curScore,
        answerkey: resAns,
        evaluationCompleted: true,
      };
      feedbackbody.answerkey.forEach((answer, index) => {
        const updatedAnswer = answerkeyset.find(
          (item) => item.question === index + 1
        );
        if (updatedAnswer) {
          answer.feedback = updatedAnswer.feedback;
        }
      });

      console.log("feedbackbody", feedbackbody);

      const feedbackres = await axios.post(
        "https://zdportalservice.azurewebsites.net/lms/updateUserResponse",
        feedbackbody,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (feedbackres?.status === 200) {
        toast.success("Feedback published successfully");
        navigate(`/lms/results?userid=${encodeURIComponent(userid)}`);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <div className="evaluation_screen">
      <div className="dashboard_heading">Welcome,</div>
      <div className="evaluation_sub_heading">
        Please read the answer and evaluate accordingly:
      </div>
      {loading && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="lms_loader"></div>
        </div>
      )}
      <div className="evaluation_item_container">
        {descQues.map((item, index) => {
          return (
            <div className="evaluation_item" key={index}>
              <div className="evaluation_question_group">
                <div>Q{index + 1}</div>
                {item?.question.map((ques, index) => {
                  return (
                    <div className="manual_question_group" key={index}>
                      {ques.type === "text" && (
                        <div className="evaluation_question">{ques.meta}</div>
                      )}
                      {ques.type === "image" && (
                        <div className="evaluation_question">
                          <img
                            src={ques.meta}
                            alt="question"
                            style={{ width: "100%" }}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="evaluation_answer">
                <span
                  style={{
                    color: "black",
                    fontSize: "1.3rem",
                  }}
                >
                  Answer:
                </span>{" "}
                &nbsp;
                {item?.wrongAnswer}
              </div>
              <div className="feedback_section">
                <div className="feedback_main_heading">
                  Provide your score and feedback
                </div>
                <div className="score_container">
                  <div className="feedback_heading">Score</div>
                  <input
                    type="number"
                    className="evaluation_input"
                    value={feedbackData[index].score}
                    onChange={(e) => handleScoreChange(index, e.target.value)}
                    onKeyDown={(e) => {
                      if (!(e.key === "Backspace" || /^\d$/.test(e.key))) {
                        e.preventDefault();
                      }
                    }}
                    min="0"
                    max="100"
                  />
                </div>
                <div className="feedback_block">
                  <div className="feedback_block_group">
                    <div className="feedback_heading">Feedback</div>
                    <textarea
                      className="evaluation_textarea"
                      value={feedbackData[index].feedback}
                      onChange={(e) =>
                        handleFeedbackChange(index, e.target.value)
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          {!loading && (
            <div className="test_submit" onClick={() => handlePublish()}>
              Publish
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManualEvaluation;
