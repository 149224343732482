import React, { useState } from "react";
import "./sidebar.css";
import hamburgerIcon from "../../../../assets/menu.png";
import closeIcon from "../../../../assets/x.png";
import { NavLink, useLocation } from "react-router-dom";
import LessonsImg from "../../../../assets/teacher.png";
import AssessmentImg from "../../../../assets/edit.png";
import ResultsImg from "../../../../assets/notepad.png";
import LessonImgActive from "../../../../assets/teacher_active_logo.png";
import ResultActiveImg from "../../../../assets/task_active.png";
import LoginActiveImg from "../../../../assets/profile.png";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material";
import { Padding } from "@mui/icons-material";

const Sidebar = ({ query, user }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const routes = [
    {
      name: "Lessons",
      icon: LessonsImg,
      activeIcon: LessonImgActive,
      path: `/lms/lesson?userid=${encodeURIComponent(query)}`,
    },
    {
      name: "Result",
      icon: ResultsImg,
      activeIcon: ResultActiveImg,
      path: `/lms/results?userid=${encodeURIComponent(query)}`,
    },
    {
      name: "Logout",
      activeIcon: LoginActiveImg,
      icon: AssessmentImg,
      path: "/lms/login",
    },
  ];

  const AdminRoutes = [
    {
      name: "Dashboard",
      icon: ResultsImg,
      activeIcon: ResultActiveImg,
      path: `/lms/admin?user=admin`,
    },
    {
      name: "Logout",
      icon: AssessmentImg,
      activeIcon: LoginActiveImg,
      path: "/lms/login",
    },
  ];

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      placement="right"
      arrow
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: "1rem",
      padding: "0.3rem 1rem",
    },
  }));

  return (
    <>
      <div className="navbar_screen">
        <div
          className="navbar_close_icon"
          onClick={() => setMenuOpen((prev) => !prev)}
        >
          <img src={menuOpen ? closeIcon : hamburgerIcon} alt="menu" />
        </div>
        <nav className="sidebar_nav_container">
          <ul className="sidebar_list">
            {user === "admin" ? (
              <>
                {AdminRoutes.map((route, index) => (
                  <li key={index}>
                    {menuOpen ? (
                      <NavLink
                        to={route.path}
                        onClick={() => setMenuOpen(false)}
                      >
                        <img
                          src={
                            route.path.includes(location?.pathname)
                              ? route.activeIcon
                              : route.icon
                          }
                        />
                        <span>{route.name}</span>
                      </NavLink>
                    ) : (
                      <BootstrapTooltip title={route.name}>
                        <NavLink
                          to={route.path}
                          onClick={() => setMenuOpen(false)}
                        >
                          <img
                            src={
                              route.path.includes(location?.pathname)
                                ? route.activeIcon
                                : route.icon
                            }
                          />
                        </NavLink>
                      </BootstrapTooltip>
                    )}
                  </li>
                ))}
              </>
            ) : (
              <>
                {routes.map((route, index) => (
                  <li key={index}>
                    {menuOpen ? (
                      <NavLink
                        to={route.path}
                        onClick={() => setMenuOpen(false)}
                      >
                        <img
                          src={
                            route.path.includes(location?.pathname)
                              ? route.activeIcon
                              : route.icon
                          }
                        />
                        <span>{route.name}</span>
                      </NavLink>
                    ) : (
                      <BootstrapTooltip title={route.name}>
                        <NavLink
                          to={route.path}
                          onClick={() => setMenuOpen(false)}
                        >
                          <img
                            src={
                              route.path.includes(location?.pathname)
                                ? route.activeIcon
                                : route.icon
                            }
                          />
                        </NavLink>
                      </BootstrapTooltip>
                    )}
                  </li>
                ))}
              </>
            )}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
