import React from "react";
import "./itemcard.css";
import { useLocation } from "react-router";
import editIcon from "../../../../assets/lms_user_edit.png";

const ItemCard = ({ image, name, onClick, score, onDelete, onEditClick }) => {
  const { pathname } = useLocation();

  return (
    <>
      <div className="dashboard_item">
        <div className="dashboard_title_group">
          <div className="dashboard_title_name">
            {name[0].toUpperCase() + name.slice(1)}
          </div>
          <div className="lms_edit_user">
            <img src={editIcon} alt="edit" onClick={onEditClick} />
          </div>
        </div>
        <div className="average_score_container">
          {/* <div className="dashboard_title">
            {pathname === "/lms/admin" ? "Average Score:" : " Score:"}
          </div> */}
          {/* <div className="dashboard_title">{score}</div> */}

          {pathname === "/lms/admin" && (
            <>
              <div className="lms_admin_delete_button" onClick={onDelete}>
                Delete
              </div>
              <div className="view_details_button" onClick={onClick}>
                View details
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ItemCard;
