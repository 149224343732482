import React from "react";
import "./lessonsPage.css";
import { useLocation } from "react-router";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const LessonsPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get("name");
  const email = searchParams.get("email");
  const set = searchParams.get("set");
  const id = searchParams.get("id");
  const userid = searchParams.get("userid");
  const caseid = searchParams.get("caseid");
  const casename = searchParams.get("casename");

  const navigate = useNavigate();

  const SubmitProgress = async () => {
    try {
      const body = {
        userid: userid,
        caseid: caseid,

        lessonCompleted: true,
        assessmentCompleted: false,
      };
      console.log("body>>>>", body);

      const res = await axios.post(
        `https://zdportalservice.azurewebsites.net/lms/postUserProgress`,
        body,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 200) {
        toast.success(res.data.message);
        console.log("res>>>>", res);
        navigate(`/lms/lesson?userid=${encodeURIComponent(userid)}`);
      }
    } catch (error) {
      console.log("Error", error);
      // Handle errors
    }
  };
  return (
    <>
      <div className="about_container">
        <div className="about_title_bar">
          <div className="about_title">
            Welcome to know your car essentials program
          </div>
        </div>
        <div className="about_content_container">
          <div className="about_content">
            <div className="about_content_title">Know your car</div>
            <div className="about_content_desc">
              The aim of the program is to equip you with the clarity of thought
              at each stage of your entrepreneurial journey - to equip you with
              the ability to ask the right questions at the right time. The next
              12 weeks will be an exciting journey for all of us. In the initial
              days, you should be used to make yourself familiar with the
              platform, learning philosophy, student support offering and
              syllabus for the upcoming months. We encourage you to listen
              carefully, to ensure all pre-requisites are taken care of. Also,
              you will have a dedicated student support manager who will help
              you troubleshoot any queries related to the program.
            </div>
            <div className="about_content_iframe">
              <iframe
                title="lesson1"
                allow="fullscreen"
                src={`https://staging.view.zdviewer.com/products/CAR_CONFIG-2?id=${encodeURIComponent(
                  id
                )}`}
              ></iframe>
            </div>
          </div>
        </div>
        <div className="about_finish_bar">
          <div className="about_finish_button" onClick={SubmitProgress}>
            Finish
          </div>
        </div>
      </div>
    </>
  );
};

export default LessonsPage;
