import React, { useState, useEffect } from "react";
import "./Products.css";

import axios from "axios";
import { Link } from "react-router-dom";

const ArmoireProducts = () => {
  const [armoireProducts, setArmoireProducts] = useState();
  const [loading, setLoading] = useState(false);
  const [showBackToTop, setShowBackToTop] = useState(false);

  useEffect(() => {
    try {
      setLoading(true);
      axios({
        method: "get",
        url: "https://zeblobstorage.blob.core.windows.net/zeblob/ProjectDb/ZDViewer/ZDViewer_Extension/StyleMe/StyleMeDashboard.json",
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        if (res.status === 200) {
          setArmoireProducts(res.data);
          setLoading(false);
        }
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 200) {
        // Adjust the threshold as needed
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {loading ? (
        <>
          <div className="lms_loader_screen" style={{ marginTop: "5rem" }}>
            <div className="lms_loader"></div>
          </div>
        </>
      ) : (
        <>
          {showBackToTop && (
            <div
              style={{
                position: "fixed",
                bottom: "2rem",
                right: "2rem",
                backgroundColor: "black",
                color: "white",
                borderRadius: "100%",
                width: "4.5rem",
                height: "4.5rem",
                zIndex: "10",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                fontSize: "0.8rem",
              }}
              onClick={scrollToTop}
            >
              BACK TO TOP
            </div>
          )}
          <div className="productsPage_container">
            {armoireProducts?.dashboard.map((product) => {
              return (
                <Link
                  key={product.id}
                  style={{ color: "inherit", textDecoration: "inherit" }}
                  to={`/armoire/products/${product.id}`}
                >
                  <div className="style_product_card">
                    <div className="style_product_image_container">
                      <img src={product.image} alt="image" />
                    </div>
                    <div className="style_product_name">{product.name}</div>
                  </div>
                </Link>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default ArmoireProducts;
