import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import correctIcon from "../../../../assets/correct.png";
import wrongIcon from "../../../../assets/delete-button.png";
import closeIcon from "../../../../assets/x.png";
import { toast } from "react-toastify";

import "./testScreen.css";

const TestScreen = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionsData, setQuestionsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [score, setScore] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [answerKey, setAnswerKey] = useState([]);
  const [userDate, setUserDate] = useState();
  const [userScore, setUserScore] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [userFeedback, setUserFeedback] = useState("");
  const [feedbackDesc, setFeedbackDesc] = useState();
  const [feebackDate, setFeedbackDate] = useState();
  const [isDisable, setIsDisable] = useState(false);
  const [feedbackParam, setFeedbackParam] = useState();
  const [descriptiveAnswer, setDescriptiveAnswer] = useState("");

  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const testtakername = searchParams.get("name");
  const email = searchParams.get("email");

  const set = searchParams.get("setid");
  const userid = searchParams.get("userid");
  const mode = searchParams.get("mode");
  const aId = searchParams.get("aId");
  const user = searchParams.get("user");
  const caseid = searchParams.get("caseid");

  useEffect(() => {
    fetch(
      `https://zdportalservice.azurewebsites.net/lms/getAssesment?setid=${encodeURIComponent(
        set
      )}`
    )
      .then((response) => response.json())
      .then((data) => {
        setQuestionsData(data);
      
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (questionsData.length) {
      setFeedbackDesc("");
      try {
        axios({
          method: "get",
          url: `https://zdportalservice.azurewebsites.net/lms/getUserResponses?userid=${userid}&setid=${set}`,
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => {
          if (res?.status === 200) {
            const aidData = res?.data?.find(
              (item) => item.id === parseInt(aId)
            );
            setFeedbackDesc(aidData.feedback);
            const feedbackDate = new Date(aidData.updatedAt).toLocaleDateString(
              "en-US",
              {
                year: "numeric",
                month: "long",
                day: "numeric",
              }
            );
            setFeedbackDate(feedbackDate);
            setUserDate(aidData.updatedAt);
            setUserScore(aidData.score);

            const renameAnswerKey = (data) =>
              data.map(({ question, ...rest }) => ({
                ...rest,
                wrongAnswer: rest.answer,
              }));

            const modifiedData = renameAnswerKey(aidData.answerkey);

            let modifiedQuestions = modifiedData.map((question) => {
              delete question.answer;
              return question;
            });

            let mergedArray = [];

            // Assuming questionsData has more elements than modifiedQuestions
            for (let i = 0; i < questionsData.length; i++) {
              let obj = questionsData[i];
              if (i < modifiedQuestions.length) {
                Object.assign(obj, modifiedQuestions[i]);
              }
              mergedArray.push(obj);
            }
            setAnswerKey(mergedArray);
            console.log("mergedArray", mergedArray);

            // setUserUserData(res?.data);
          } else {
            alert("Oops! Something went wrong. Please try again.");
          }
        });
      } catch (error) {
        console.error("Error occurred during login:", error);
      }
    }
  }, [questionsData]);

  const updateSelection = (isSelected, optionIndex) => {
    let selectedOptions =
      questionsData[currentQuestionIndex].selectedOptions || [];
    switch (questionsData[currentQuestionIndex].answerType) {
      case "sca":
        selectedOptions = [optionIndex];
        break;
      case "mca":
        if (isSelected) {
          selectedOptions.push(optionIndex);
        } else {
          selectedOptions = selectedOptions.filter(
            (option) => option !== optionIndex
          );
        }
        break;
    }
    setQuestionsData(
      questionsData.map((question, index) => ({
        ...question,
        selectedOptions:
          index === currentQuestionIndex
            ? selectedOptions
            : question.selectedOptions,
      }))
    );
  };

  const handleNext = () => {
    const currentQuestion = questionsData[currentQuestionIndex];

    // If it's a descriptive question, update the answerkey and move to the next question
    if (currentQuestion.answerType === "descriptive") {
      // Update the answerkey with the descriptive answer
      const answerkeyset = [...answerKey];
      answerkeyset[currentQuestionIndex] = {
        question: currentQuestionIndex,
        answer: descriptiveAnswer,
        feedback: [],
      };
      setAnswerKey(answerkeyset);
      console.log("answerkeyset",answerkeyset)
    
      const alteredlol=answerkeyset.find((ques)=>ques.question===currentQuestionIndex+1)
      setDescriptiveAnswer(alteredlol?.answer || "")
      console.log("currentQuestionIndex",currentQuestionIndex)
      console.log("alteredlol",alteredlol)
     
      // Move to the next question
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      return;
    }

    // For other types of questions, handle selected options as before
    // Get the selected options for the current question
    const selectedOptions = Array.from(
      document.querySelectorAll(
        `input[name="question_${currentQuestionIndex}"]:checked`
      )
    ).map((option) => parseInt(option.value)); // Parse values to integers

    // Check if any option is selected
    if (questionsData[currentQuestionIndex].answerType === "descriptive") {
      if (descriptiveAnswer === "") {
        // Don't proceed if no option is selected
        toast.warning("Enter answer to proceed!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "light",
        });
        return;
      }
    } else if (selectedOptions.length === 0) {
      // Don't proceed if no option is selected
      toast.warning("Select an Option to proceed!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "light",
      });
      return;
    }

    // Move to the next question
    setCurrentQuestionIndex(currentQuestionIndex + 1);

    let isCorrectOption = false;

    // Check if all correct options are selected
    const correctOptions = questionsData[currentQuestionIndex].answer.map(
      (option) => option.toString()
    );
    isCorrectOption = correctOptions.every((option) =>
      selectedOptions.includes(parseInt(option))
    );

    const curScore = score + (isCorrectOption ? 1 : 0);

    setScore(curScore);
    // Update the answerkey with the selected options for non-descriptive questions
    const answerkeyset = [...answerKey];
    answerkeyset[currentQuestionIndex] = {
      question: currentQuestionIndex,
      answer: selectedOptions,
      feedback: [],
    };
    setAnswerKey(answerkeyset);
  };
  const handleSkip = () => {
    const answer = "na";
    
    const curScore = score;
    setScore(curScore);

    const answerkeyset = [...answerKey];
    answerkeyset[currentQuestionIndex] = {
      question: currentQuestionIndex,
      answer: answer,
      feedback: [],
    };
    setAnswerKey(answerkeyset);

    const alteredlol=answerkeyset.find((ques)=>ques.question===currentQuestionIndex+1)
    setDescriptiveAnswer(alteredlol?.answer || "")

    // Move to the next question
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handlePrevious = (index) => {
  
    const alteredlol=answerKey.filter((ques)=>ques.question===index-1)
    if(alteredlol[0].answer == "na")
      setDescriptiveAnswer("")
    else
      setDescriptiveAnswer(alteredlol[0].answer)
   
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  const handleSubmit = async () => {
    setIsDisable(true);

    if (!submitted) {
      let isCorrectOption = false;
      const selectedOptions = Array.from(
        document.querySelectorAll(
          `input[name="question_${currentQuestionIndex}"]:checked`
        )
      ).map((option) => option.value);

      if (selectedOptions.length > 0) {
        isCorrectOption = selectedOptions.includes(
          questionsData[currentQuestionIndex].answer.toString()
        );
      }

      const curScore = score + (isCorrectOption ? 1 : 0);

      setScore(curScore);
      setSubmitted(true);

      // const answerkeyset = questionsData.slice(0, questionsData.length - 1).map((question, index) => {
      // 	return {
      // 		question: index + 1,
      // 		answer: Array.isArray(question.selectedAnswer) ? question.selectedAnswer :  [question.selectedAnswer] //question.selectedAnswer ? [question.selectedAnswer] : 'na'
      // 	};
      // });

      let answerkeyset = answerKey;
      let answer = selectedOptions.map((option) => parseInt(option));

      // console.log("answer ", answer);
      if (questionsData[currentQuestionIndex].answerType === "descriptive") {
        answer = descriptiveAnswer;
      }
      if (answer.length === 0) answer = "na";

      answerkeyset.push({
        question: currentQuestionIndex,
        answer: answer,
        feedback: [],
        //.length > 0 ? selectedOptions : 'na'
      });
      const isDescriptive = questionsData.some(
        (item) => item.answerType === "descriptive"
      );

      let Finalscore = 0;
      for (let i = 0; i < questionsData.length; i++) {
        const questionAnswer = questionsData[i].answer;
        const correctAnswer = answerKey[i].answer;
        console.log("questionAnswer", questionAnswer);
        console.log("correctAnswer", correctAnswer);
        const questionType = questionsData[i].answerType;

        if (questionType === "sca") {
          if (
            correctAnswer !== "na" &&
            correctAnswer.every((val) => questionAnswer.includes(val))
          ) {
            Finalscore++;
            console.log("Finalscore each", Finalscore);
          } 
        }else if (questionType === "mca") {
          let allCorrect = true; // Assume all correct options are chosen initially
          for (let j = 0; j < correctAnswer.length; j++) {
              if (!questionAnswer.includes(correctAnswer[j])) {
                  allCorrect = false; // If any correct option is not chosen, set allCorrect to false
                  break;
              }
          }
          if (allCorrect && questionAnswer.length === correctAnswer.length) {
              Finalscore++;
              console.log("Finalscore each", Finalscore);
          }
      }  else if (questionType === "descriptive") {
          Finalscore = Finalscore;
        }
      }

   

      try {
        const body = {
          userid: userid,
          setid: set,
          score: Finalscore,
          answerkey: answerkeyset,
          evaluationCompleted: isDescriptive ? false : true,
        };

        const res = await axios.post(
          `https://zdportalservice.azurewebsites.net/lms/postUserResponse`,
          body,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        if (res?.status === 200) {
        } else {
          // Handle other status codes or scenarios
        }
      } catch (error) {
        console.log("Error", error);
        // Handle errors
      }
    }

    try {
      const body = {
        userid: userid,
        caseid: caseid,
        lessonCompleted: true,
        assessmentCompleted: true,
      };
      const res = await axios.post(
        `https://zdportalservice.azurewebsites.net/lms/postUserProgress`,
        body,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status === 200) {
        toast.success(res.data.message);
        setIsDisable(false);
        navigate(`/lms/lesson?userid=${encodeURIComponent(userid)}`);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };

  if (isLoading) {
    return (
      <div className="loading_screen">
        <div className="lms_loader"></div>
      </div>
    );
  }

  const viewFeedback = (param) => {
    setOpenModal(!openModal);
    setFeedbackParam(param);
  };

  const publishFeedback = async () => {
    try {
      const body = {
        id: aId,
        userid: userid,
        setid: set,
        feedback: userFeedback,
      };

      const res = await axios.post(
        "https://zdportalservice.azurewebsites.net/lms/updateUserResponse",
        body,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (res?.status === 200) {
        toast.success(res.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,

          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setOpenModal(!openModal);
        navigate(
          `/lms/results?userid=${encodeURIComponent(userid)}&user=admin`
        );
      } else {
        // Handle other status codes or scenarios
      }
    } catch (error) {
      console.log("Error", error);
      // Handle errors
    }
  };

  return (
    <>
      {openModal && (
        <div className="modal_screen">
          <div className="modal_container">
            <div
              className="modal_close_container"
              onClick={() => setOpenModal(!openModal)}
            >
              <img src={closeIcon} alt="close" />
            </div>
            <div className="modal_heading">
              {user === "admin" && !feedbackParam
                ? "Provide Your feedback"
                : "Feedback"}
            </div>
            {user === "admin" && !feedbackParam ? (
              <>
                <div className="modal_textarea_group">
                  <textarea
                    placeholder="enter your feedback"
                    onChange={(e) => setUserFeedback(e.target.value)}
                  />
                  <div className="test_submit" onClick={publishFeedback}>
                    Publish
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="modal_date">Date : {feebackDate}</div>
                <div className="modal_desc">{feedbackDesc}</div>
              </>
            )}
          </div>
        </div>
      )}
      <>
        <div className="testscreen_container">
          <div
            className="testscreen_user"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div> Welcome </div>
            {feedbackDesc && (
              <>
                {mode === "answers" && (
                  // <div className="test_submit" onClick={viewFeedback}>
                  //   {user === "admin" ? "Add Feedback" : "View Feedback"}
                  // </div>
                  <>
                    {user === "admin" ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "2rem",
                          }}
                        >
                          <div
                            className="test_submit"
                            onClick={() => viewFeedback("view")}
                          >
                            View Feedback
                          </div>
                          <div
                            className="test_submit"
                            onClick={() => viewFeedback("")}
                          >
                            Add Feedback
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="test_submit"
                          onClick={() => viewFeedback("view")}
                        >
                          View Feedback
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>

          {mode === "questions" && (
            <div className="test_heading">
              Please read the questions thoroughly and choose the right answer.
            </div>
          )}
          {mode === "answers" && (
            <div className="date_time_group">
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  Date:
                  {userDate ? new Date(userDate).toLocaleDateString() : ""}
                </div>
                <div>
                  Time:
                  {userDate ? new Date(userDate).toLocaleTimeString() : ""}
                </div>
              </div>
              <div>Score: {userScore}</div>
            </div>
          )}

          <div className="test_questions_container">
            {mode !== "questions" ? (
              answerKey?.map((question, index) => (
                <div className="test_card" key={index}>
                  <div className="test_question_group">
                    {question.question.map((question, i) => (
                      <React.Fragment key={i}>
                        {question.type === "text" && (
                          <div>
                            Q{index + 1}.&nbsp; {question.meta}
                          </div>
                        )}
                        {question.type === "image" && (
                          <div className="test_question_group_image">
                            <img src={question.meta} alt="question" />{" "}
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                  <div className="options_container">
                    {/* Render options based on the answer type */}
                    {question.answerType === "descriptive" && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column-reverse",
                          gap: "1rem",
                          width: "100%",
                        }}
                      >
                        <div>
                          {question?.feedback?.map((feed) => {
                            return (
                              <div>
                                <div
                                  className=""
                                  style={{ fontSize: "1.2rem" }}
                                >
                                  Feedback: {feed.feedback}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <textarea
                          className="test_textarea"
                          placeholder="Type your answer here"
                          value={question.wrongAnswer}
                          disabled
                        ></textarea>
                      </div>
                    )}
                    {question?.options?.map((option, optionIndex) => (
                      <div className="optiongroup" key={optionIndex}>
                        {question?.answer &&
                        question?.answer?.includes(optionIndex + 1) ? (
                          <img src={correctIcon} className="correctIcon" />
                        ) : question.wrongAnswer.includes(optionIndex + 1) ? (
                          <img src={wrongIcon} className="correctIcon" />
                        ) : (
                          <input
                            className="test_checkbox"
                            type="radio"
                            id={`option_${index}_${optionIndex}`}
                            name={`question_${index}`}
                            value={optionIndex + 1}
                            disabled={mode === "questions" ? false : true}
                          />
                        )}
                        <label htmlFor={`option_${index}_${optionIndex}`}>
                          {option.type === "image" ? (
                            <img src={option.meta} alt="option" />
                          ) : (
                            <span> {option.meta}</span>
                          )}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <>
                <div className="test_card">
                  <div className="test_question_group">
                    {questionsData[currentQuestionIndex].question.map(
                      (question) => {
                        return (
                          <>
                            {question.type === "text" ? (
                              <div>
                                {" "}
                                Q{currentQuestionIndex + 1}.&nbsp;{" "}
                                {question.meta}
                              </div>
                            ) : (
                              ""
                            )}
                            {question.type === "image" ? (
                              <div className="test_question_group_image">
                                <img src={question.meta} alt="question" />{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      }
                    )}
                  </div>
                  <div className="options_container">
                    {questionsData[currentQuestionIndex].answerType ===
                      "descriptive" && (
                      <>
                        <textarea
                          className="test_textarea"
                          placeholder="Type your answer here"
                          onChange={(e) => setDescriptiveAnswer(e.target.value)}
                          value={descriptiveAnswer}
                        ></textarea>
                      </>
                    )}
                    {questionsData[currentQuestionIndex].options.map(
                      (option, optionIndex) => (
                        <div
                          className="optiongroup"
                          key={currentQuestionIndex + "-" + optionIndex}
                        >
                          {questionsData[currentQuestionIndex].answerType ===
                            "sca" && (
                            <>
                              <input
                                className="test_radio"
                                type="radio"
                                id={`option_${currentQuestionIndex}_${optionIndex}`}
                                name={`question_${currentQuestionIndex}`}
                                value={optionIndex + 1}
                                onClick={(event) =>
                                  updateSelection(
                                    event.target.checked,
                                    optionIndex + 1
                                  )
                                }
                                checked={questionsData[
                                  currentQuestionIndex
                                ]?.selectedOptions?.includes(optionIndex + 1)}
                              />
                              <label
                                htmlFor={`option_${currentQuestionIndex}_${optionIndex}`}
                              >
                                {option.type === "image" ? (
                                  <img src={option.meta} alt="option" />
                                ) : (
                                  <span> {option.meta}</span>
                                )}
                              </label>
                            </>
                          )}
                          {questionsData[currentQuestionIndex].answerType ===
                            "mca" && (
                            <>
                              <input
                                className="test_checkbox"
                                type="checkbox"
                                id={`option_${currentQuestionIndex}_${optionIndex}`}
                                name={`question_${currentQuestionIndex}`}
                                value={optionIndex + 1}
                                onClick={(event) =>
                                  updateSelection(
                                    event.target.checked,
                                    optionIndex + 1
                                  )
                                }
                                checked={questionsData[
                                  currentQuestionIndex
                                ]?.selectedOptions?.includes(optionIndex + 1)}
                              />
                              <label
                                htmlFor={`option_${currentQuestionIndex}_${optionIndex}`}
                              >
                                {option.meta}
                              </label>
                            </>
                          )}
                        </div>
                      )
                    )}
                  </div>
                </div>
              </>
            )}

            {mode === "questions" &&
            currentQuestionIndex === questionsData.length - 1 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "1rem",
                  flexDirection: "row-reverse",
                  width: "100%",
                }}
              >
                <div
                  className="test_submit"
                  style={{ pointerEvents: isDisable ? "none" : "" }}
                  onClick={handleSubmit}
                >
                  Submit
                </div>

                {currentQuestionIndex > 0 && (
                  <div className="test_submit" onClick={()=>handlePrevious(currentQuestionIndex)}>
                    Previous
                  </div>
                )}
              </div>
            ) : mode === "questions" &&
              currentQuestionIndex !== questionsData.length - 1 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "1rem",
                    flexDirection: "row-reverse",
                    width: "100%",
                  }}
                >
                  <div className="test_submit" onClick={handleNext}>
                    Next
                  </div>
                  <div className="skip_question" onClick={() => handleSkip()}>
                    Skip
                  </div>
                  {currentQuestionIndex > 0 && (
                    <div className="test_submit" onClick={()=>handlePrevious(currentQuestionIndex)}>
                      Previous
                    </div>
                  )}
                </div>
                <div style={{ fontSize: "1.2rem" }}>
                  {currentQuestionIndex + 1} / {questionsData.length}
                </div>
              </>
            ) : (
              <div />
            )}
          </div>
        </div>
      </>
    </>
  );
};

export default TestScreen;
