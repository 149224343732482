import React from "react";
import "./lmsPopup.css";
import closeIcon from "../../../../assets/x.png";
import { useLocation } from "react-router-dom";
const LmsPopup = ({ desc, OnClose, name, onClick }) => {
  const location = useLocation();

  return (
    <div className="lms_popup_box">
      <div className="lms_close_icon" onClick={OnClose}>
        <img src={closeIcon} alt="close-icon" />
      </div>
      <div className="lms_popup_contents">
        <div className="lms_popup_desc">{desc}</div>
        {location.pathname === "/lms/admin" && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <div className="lms_modal_button" onClick={onClick}>
                {name}
              </div>
              <div className="lms_modal_cancel" onClick={OnClose}>
                Cancel
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LmsPopup;
