import React, { useState, useEffect } from "react";
import "./cohortMenu.css";
import closeIcon from "../../../../assets/x.png";
import downIcon from "../../../../assets/down.png";
import { MergeTypeSharp } from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify";

const CohortMenu = ({ onclose, cohortData }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedId, setSelectedId] = useState();
  const [currentCohort, setCurrentCohort] = useState();

  useEffect(() => {
    if (cohortData) {
      const defaultcohort = cohortData.coherts.find(
        (item) => item.cohertid === cohortData.user.cohertid
      );

      if (defaultcohort) {
        setSelectedOption(defaultcohort.name);
        setSelectedId(defaultcohort.cohertid);
        setCurrentCohort(defaultcohort.name);
      }
    }
  }, [cohortData]);

  const handleSelectCohort = (cohort) => {
    setSelectedOption(cohort.name);
    setOpenDropdown(false);
    setSelectedId(cohort.cohertid);
  };
  const handleCohortSubmit = async () => {
    const body = {
      cohertid: selectedId,
      userid: cohortData.user.userid,
    };

    try {
      await axios({
        method: "post",
        url: "https://zdportalservice.azurewebsites.net/lms/updateCohert",
        headers: {
          "Content-Type": "application/json",
        },
        data: body,
      }).then((res) => {
        if (res.status === 200) {
          toast.success("Cohort Assigned Successfully");
          setSelectedOption(null);
          setSelectedId(null);
          setCurrentCohort(null);
        }
      });
    } catch (err) {
      console.log("error", err);
    }

    onclose();
  };
  return (
    <div className="cohortMenu_container">
      <div className="cohortMenu_close_icon" onClick={onclose}>
        <img src={closeIcon} alt="close-icon" />
      </div>
      <div className="cohortMenu_contents">
        <div className="cohort_title">
          {cohortData?.user?.username[0].toUpperCase() +
            cohortData?.user?.username.slice(1)}
        </div>
        <div className="cohort_subheading">
          Existing cohort type: {currentCohort || "Not Assigned yet"}
        </div>
        <div className="cohort_dropdown_container">
          <div className="cohort_dropdown_heading">Assign a new cohort</div>
          <div className="cohort_dropdown">
            <div className="cohort_dropdown_menu_heading">
              {selectedOption || "Assign a Cohort"}
            </div>
            <img
              src={downIcon}
              alt="down icon"
              onClick={() => setOpenDropdown(!openDropdown)}
            />
            {openDropdown && (
              <div className="cohort_downMenu">
                {cohortData.coherts.map((item) => {
                  return (
                    <div
                      className="cohort_downMenu_item"
                      onClick={() => handleSelectCohort(item)}
                      key={item.id}
                    >
                      {item.name}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="cohort_button_container">
          <div className="test_submit" onClick={handleCohortSubmit}>
            Submit
          </div>
        </div>
      </div>
    </div>
  );
};

export default CohortMenu;
