import React, { lazy, Suspense } from "react";
import loaderGifs from "../src/assets/loading2k.gif";
import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "@fontsource/roboto-slab";
import Loader from "../src/components/loader";
import ViewDemo from "./screens/viewdemo";
import PDP from "./screens/pdp";
import Login from "./screens/lms/pages/login";
import Lessons from "./screens/lms/pages/lessons";
import LessonsPage from "./screens/lms/pages/about";
import TestScreen from "./screens/lms/pages/qanda";
import AdminDashboard from "./screens/lms/pages/dashboard";
import UserDashboard from "./screens/lms/pages/viewResults";
import Navbar from "../src/screens/lms/components/navbar";
import Performance from "./screens/lms/pages/performance";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "./screens/lms/components/sidebar";
import ManualEvalution from "./screens/lms/pages/manualEvaluation";

import ArmoireLandingPage from "./screens/armoire/pages/armoireLandingPage";
import ArmoireNavbar from "./screens/armoire/components/Navbar";
import ArmoireProducts from "./screens/armoire/pages/products";
import ArmoireProductPage from "./screens/armoire/pages/productPage";

function App() {
  const LandingPage = lazy(() => import("./screens/home"));

  return (
    <Suspense fallback={<Loader loaderGif={loaderGifs} />}>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/viewdemo" element={<ViewDemo />} />
          <Route exact path="/pdp" element={<PDP />} />

          <Route path="/lms/login" element={<Login />} />
          <Route
            exact
            path="/lms/lesson"
            element={
              <>
                {/* <Navbar /> */}

                <Lessons />
              </>
            }
          />
          <Route
            path="/lms/lesson/1"
            element={
              <>
                {/* <Navbar /> */}
                <LessonsPage />
              </>
            }
          />
          <Route
            path="/lms/assessment"
            element={
              <>
                {/* <Navbar /> */}
                <TestScreen />
              </>
            }
          />
          <Route
            path="/lms/admin"
            element={
              <>
                {/* <Navbar /> */}
                <AdminDashboard />
              </>
            }
          />
          <Route
            path="/lms/results"
            element={
              <>
                {/* <Navbar /> */}
                <UserDashboard />
              </>
            }
          />
          <Route
            path="/lms/results/performance"
            element={
              <>
                {/* <Navbar /> */}
                <Performance />
              </>
            }
          />
          <Route
            path="/lms/evaluation"
            element={
              <>
                {/* <Navbar /> */}
                <ManualEvalution />
              </>
            }
          />
          <Route path="/lms/*" element={<Navigate replace to="/lms/login" />} />
          <Route
            path="/armoire"
            element={
              <>
                <ArmoireNavbar />
                <ArmoireLandingPage />
              </>
            }
          />
          <Route
            path="/armoire/products"
            element={
              <>
                <ArmoireNavbar />
                <ArmoireProducts />
              </>
            }
          />
          <Route
            path="/armoire/products/:id"
            element={
              <>
                <ArmoireNavbar />
                <ArmoireProductPage />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
