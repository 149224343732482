import React from "react";
import "./armoireLandingPage.css";
import StyleUsButton from "../../components/StyleUsButton";
import armoireVid from "../../../../assets/test new.mp4";

const ArmoireLandingPage = () => {
  const [onMouseOverVF, setOnMouseOverVF] = React.useState(false);
  return (
    <>
      <div className="armiore_video_container">
        <video src={armoireVid} autoPlay loop muted />
      </div>
      <div className="armoire_text_container">
        <div className="styleUs_heading">
          A New Way to Experience Digital Fashion
        </div>
        <div className="stylus_button_group">
          {/* <StyleUsButton
               onMouseEnter={()=>setOnMouseOverDF(true)}
               onMouseLeave={()=>setOnMouseOverDF(false)}
            style={{backgroundColor:onMouseOverDF?'white':'#E0115F',
                    color:onMouseOverDF?'black':'white'}}
             label='Digital Fashion'/> */}

          <StyleUsButton
            onMouseEnter={() => setOnMouseOverVF(true)}
            Linker="/armoire/products"
            onMouseLeave={() => setOnMouseOverVF(false)}
            style={{
              backgroundColor: onMouseOverVF ? "#4169e1" : "white",
              color: onMouseOverVF ? "white" : "black",
            }}
            label="Virtual Fitting"
          />
        </div>
      </div>
    </>
  );
};

export default ArmoireLandingPage;
